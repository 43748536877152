import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';
import './Register.css';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://pixelsbyme.se/">
                PixelsByMe
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const handleRegister = async (credentials) => {
    try {
        const response = await axios.post('/auth/register', credentials);
        return response.data;
    } catch (error) {
        return {
            error: true,
            message: error.response.data
        };
    }
};

export default function Register() {
    const { isAuthenticated, register, setUser } = useAuth();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            const data = new FormData(event.currentTarget);
            let credentials = {
                email: data.get('email'),
                password: data.get('password'),
                invitationCode: data.get('invitationCode')
            }

            const result = await handleRegister(credentials);
            if (result.error) {
                setErrorMessage(result.message);
                return;
            }

            setErrorMessage('');
            if (result) {
                setUser(result.user);
                register(result);
            }
        } else {
            // Show an error message or handle invalid form inputs
            console.error('Passwords do not match');
        }
    };

    const validateForm = () => {
        const { password, confirmPassword } = formData;
        if (password.length < 8) {
            setErrorMessage('Lösenordet måste vara minst 8 tecken långt');
            return false;
        } else if (password !== confirmPassword) {
            setErrorMessage('Lösenorden matchar inte');
            return false;
        }

        setErrorMessage('');
        return true;
    };

    // Redirect if authenticated
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>

                </Avatar>
                <Typography component="h1" variant="h5">
                    Ny användare
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={formData.username}
                        onChange={handleInputChange}
                        id="email"
                        label="E-postadress"
                        name="email"
                        type='email'
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={formData.password}
                        onChange={handleInputChange}
                        name="password"
                        label="Lösenord"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        name="confirmPassword"
                        label="Upprepa lösenord"
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="invitationCode"
                        label="Invitation code"
                        type="text"
                        id="invitationCode"
                    />
                    <div className="error-message">
                        {errorMessage && <p>{errorMessage}</p>}
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Registrera
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {"Har du redan ett konto? Logga in här"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}