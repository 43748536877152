import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { Modal, Box } from '@mui/material';

const ImageEditorModal = ({ imageData, selectedIndex, signatureUrls, handleClose }) => {
    const canvasRef = useRef();
    const [canvasSize, setCanvasSize] = useState({ width: 500, height: 500 });
    const [fabricCanvas, setFabricCanvas] = useState(null);

    useEffect(() => {
        const canvas = new fabric.Canvas(canvasRef.current);
        setFabricCanvas(canvas); // Store the fabric canvas instance
        new fabric.Image.fromURL(`https://storage.googleapis.com/pixelsbyme/${imageData[selectedIndex].watermarkedImage}`, (img) => {
            console.log('Image loaded ', img.width, img.height);
            setCanvasSize({ width: img.width, height: img.height });
            img.set({
                left: 0,
                top: 0,
                selectable: false,
            });
            canvas.add(img).sendToBack(img);
            canvas.renderAll();
        });
    }, [imageData, selectedIndex]);

    const handleSignatureClick = (signatureUrl) => {
        console.log('Signature clicked', signatureUrl);
        if (fabricCanvas) {
            console.log('Adding signature to canvas');
            fabric.Image.fromURL(signatureUrl, (img) => {
                img.set({
                    left: 50,
                    top: 50,
                    borderColor: 'red',
                    cornerColor: 'green',
                    cornerSize: 10,
                    transparentCorners: false,
                    hasRotatingPoint: true,
                });
                fabricCanvas.add(img); // Use the stored fabric canvas instance
            });
        }
    }

    return (
        <Modal open={true} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'auto' }}>
            <Box style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                outline: 'none',
                maxHeight: '90vh', // Max height for the modal content
                overflow: 'auto' // Enable scroll if content exceeds the max height
            }}>
                <canvas ref={canvasRef} width={canvasSize.width} height={canvasSize.height} />

                <div style={{ display: 'flex', marginTop: '20px' }}>
                    {signatureUrls.map((url, index) => (
                        <img key={index} src={url} alt={`Signature ${index}`} onClick={() => handleSignatureClick(url)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                    ))}
                </div>
            </Box>
        </Modal>
    );
};

export default ImageEditorModal;
