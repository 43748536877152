import React, { useEffect, useRef, useState } from 'react';
import './Chat.css'
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';
import { Modal } from '@mui/material';

const Chat = () => {
    // Example message structure:
    // { type: 'text', text: 'Hello', sender: 'user' }
    // { type: 'image', imageUrl: 'http://example.com/image.jpg', sender: 'bot' }
    const [messages, setMessages] = useState([]);

    const [newMessage, setNewMessage] = useState('');
    const [conversationIds, setConversationIds] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useAuth();

    const bottomRef = useRef(null);

    useEffect(() => {
    //    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const createImage = async (prompt) => {
        setIsLoading(true);
        try {
            const matches = prompt.match(/\[(.*?)\]/);
            var extractedPrompt = '';
            if (matches) {
                extractedPrompt = matches[1];
            } else {
                extractedPrompt = prompt;
            }

            const response = await axios.post('/image/create', { prompt: extractedPrompt });
            const imageUrl = response.data.url;
            setMessages(prevMessages => [...prevMessages, { type: 'image', imageUrl, sender: 'bot' }]);
        } catch (error) {
            setMessages([...messages, { type: "text", text: "Något blev tyvärr fel [ERROR: Create image failed]", sender: 'bot' }]);
        } finally {
            setIsLoading(false);
        }
    };

    const checkStatus = async (runId, threadId) => {
        try {
            const response = await axios.get('/conversation/status?runId=' + runId + '&threadId=' + threadId);
            return response.data;
        } catch (error) {
            setMessages([...messages, { type: "text", text: "Något blev tyvärr fel", sender: 'bot' }]);
            return false;
        }
    };

    const handleSendMessage = async () => {
        if (!user || !user.isTrialUser) {
            setIsModalOpen(true);
            return;
        }

        if (!newMessage.trim()) return;
        setIsLoading(true);

        // Add new message to messages array
        setMessages([...messages, { type: "text", text: newMessage, sender: 'user' }]);
        setNewMessage('');

        var body;
        if (conversationIds.threadId === undefined) {
            body = {
                message: newMessage,
            };
        } else {
            body = {
                message: newMessage,
                threadId: conversationIds.threadId,
            };
        }

        try {
            const result = await axios.post(
                '/conversation/message',
                body
            );

            setConversationIds(result.data);

            const maxRetries = 20; // Maximum number of retries
            const timeout = 40000; // Timeout in milliseconds (40 seconds)
            const startTime = Date.now();

            let retries = 0;
            let completed = false;
            let statusData = {};

            while (!completed && retries < maxRetries && (Date.now() - startTime) < timeout) {
                await new Promise(resolve => setTimeout(resolve, 2000)); // 2 second delay between each check
                statusData = await checkStatus(result.data.id, result.data.threadId);
                completed = statusData.status === 'completed';
                retries++;
            }

            if (!completed) {
                setMessages([...messages, { type: "text", text: "Något blev tyvärr fel. Ladda om sidan och testa på nytt. [ERROR: Timeout]", sender: 'bot' }]);
            } else {
                const messages = statusData.messages.body.data;
                const firstAssistantMessage = messages.find(message => message.role === 'assistant');

                firstAssistantMessage.content.forEach(contentItem => {
                    if (contentItem.type === 'text' && contentItem.text.value.startsWith('[')) {
                        setMessages(prevMessages => [...prevMessages, { type: "text", text: "Nu har jag allt jag behöver och skapar just nu ditt konstverk! Häng kvar!" + contentItem.text.value, sender: 'bot' }]);
                        createImage(contentItem.text.value);
                    } else if (contentItem.type === 'text') {
                        setMessages(prevMessages => [...prevMessages, { type: "text", text: contentItem.text.value, sender: 'bot' }]);
                        setIsLoading(false);
                    }
                });
                setNewMessage('');
            }

        } catch (error) {
            setMessages([...messages, { type: "text", text: "Något blev tyvärr fel [ERROR: Send message failed]", sender: 'bot' }]);
            setIsLoading(false);
        }
    };

    const renderMessages = () => {
        return messages.map((message, index) => {
            if (message.type === 'text') {
                return <div key={index} className={`message ${message.sender}`}>{message.text}</div>;
            } else if (message.type === 'image') {
                return <div key={index} className={`message ${message.sender}`}>
                    <img src={message.imageUrl} />
                </div>;
            }
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleSendMessage();
        }
      };


    return (
        <div className="chat-container">

            <div className="chatContainer">
                {renderMessages()}
                {isLoading && <div className="spinner"></div>}
                <div ref={bottomRef} />
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={newMessage}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setNewMessage(e.target.value)}
                />
                <button onClick={handleSendMessage} disabled={isLoading}>Send</button>
            </div>

            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <p>You must be a trial user or an admin to send messages.</p>
            </Modal>
        </div>
    );
};

export default Chat;
