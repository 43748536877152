import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';
import { Navigate } from 'react-router-dom';
import { Paper } from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://pixelsbyme.se/">
                PixelsByMe
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const handleLogin = async (credentials) => {
    try {
        const response = await axios.post('/auth/login', credentials);

        if (response.status !== 200) {
            throw new Error('Login failed');
        }

        return response.data;
    } catch (error) {
        return {
            error: true,
            message: "Felaktig e-postadress eller lösenord"
        };
    }
};

export default function SignIn() {
    const { isAuthenticated, login, setUser } = useAuth();
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let credentials = {
            email: data.get('email'),
            password: data.get('password')
        }

        try {
            const result = await handleLogin(credentials);
            if (result.error) {
                setErrorMessage(result.message);
                return;
            }
            setUser(result.user);
            login(result);
        } catch (error) {
            console.log(error);
            setErrorMessage("Något blev tyvärr fel");
        }
    };

    // Redirect if authenticated
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url('/images/sign_in_bg.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>

                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Logga in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-postadress"
                            name="email"
                            type='email'
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Lösenord"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <div className="error-message">
                            {errorMessage && <p>{errorMessage}</p>}
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Logga in
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Glömt lösenord?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Har du inget konto? Registrera dig här"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}