import { useCallback, useEffect, useRef } from 'react';
import './ImageModal.css';

const ImageModal = ({ imageData, selectedIndex, onClose, onChangeImage }) => {
    // Function to handle outside click
    const handleOutsideClick = (e, modalContentRef) => {
        if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
            onClose();
        }
    };

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowRight') {
            onChangeImage(selectedIndex + 1);
        } else if (event.key === 'ArrowLeft') {
            onChangeImage(selectedIndex - 1);
        }
    }, [selectedIndex, onChangeImage]);

    // Create a ref for the modal content
    const modalContentRef = useRef();

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', (e) => handleOutsideClick(e, modalContentRef));

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', (e) => handleOutsideClick(e, modalContentRef));
        };
    }, [handleKeyDown]);

    const currentImage = imageData[selectedIndex];

    const path = currentImage.watermarkedImage !== undefined
        ? `https://storage.googleapis.com/pixelsbyme/${currentImage.watermarkedImage}`
        : `https://storage.googleapis.com/pixelsbyme/${currentImage.imageUrl}`;


    return (
        <div className="modal-backdrop">
            <div className="modal-content" ref={modalContentRef}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <span className="modal-arrow left" onClick={() => onChangeImage(selectedIndex - 1)}>&lt;</span>
                <img src={`https://storage.googleapis.com/pixelsbyme/${currentImage.watermarkedImage}`} alt="Selected" />
                <span className="modal-arrow right" onClick={() => onChangeImage(selectedIndex + 1)}>&gt;</span>

            </div>
        </div>
    );
};

export default ImageModal;
