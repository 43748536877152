import React, { useRef, useState } from 'react';
import { Button, Slider, Box, Typography } from '@mui/material';

import './SignaturePad.css';

const SignaturePad = ({ onSave }) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [brushSize, setBrushSize] = useState(3);

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        const ctx = canvasRef.current.getContext('2d');
        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const finishDrawing = () => {
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) {
            return;
        }
        const { offsetX, offsetY } = nativeEvent;
        const ctx = canvasRef.current.getContext('2d');
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = brushSize; // Use state for size
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
    };

    const clearCanvas = () => {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const saveSignature = () => {
        const image = canvasRef.current.toDataURL();
        onSave(image);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <canvas
                ref={canvasRef}
                width="365"
                height="250"
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                onMouseLeave={finishDrawing}
                style={{ border: '1px solid black' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <Typography sx={{ marginRight: 2 }}>Brush Size:</Typography>
                <Slider
                    value={brushSize}
                    onChange={(e, newValue) => setBrushSize(newValue)}
                    step={1}
                    min={1}
                    max={10}
                    valueLabelDisplay="auto"
                    sx={{ width: 200, marginRight: 2 }}
                />
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" onClick={clearCanvas}>Rensa</Button>
                <Button variant="contained" onClick={saveSignature}>Spara signatur</Button>
            </Box>
        </Box>
    );
};

export default SignaturePad;
