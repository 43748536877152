
import axios from 'axios';
import Header from '../components/Header';
import { useEffect, useState } from 'react';
import './HomePage.css';
import ImageModal from './ImageModal';
import SignaturePad from '../components/SignaturePad';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ImageEditorModal from '../components/ImageEditorModal';

const HomePage = () => {
    const [imageData, setImageData] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedSignatureIndex, setSelectedSignatureIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSignatureModalOpen, setSignatureModalOpen] = useState(false);
    const [signatures, setSignatures] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/user/images');
                console.log("Fetch images", response.data);
                setImageData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchSignatures = async () => {
            try {
                const response = await axios.get('/user/signatures');
                setSignatures(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchImages();
        fetchSignatures();
    }, []);

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    const handleSaveSignature = async (signature) => {
        try {
            const response = await axios.post('/user/signatures', { signature: signature });
            console.log(response.data); // Handle response
        } catch (error) {
            console.error(error); // Handle error
        }

        handleClose();
    };

    const handleSignatureClick = (index) => {
        setSelectedSignatureIndex(index);
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const handleChangeImage = (newIndex) => {
        if (newIndex >= 0 && newIndex < imageData.length) {
            setSelectedImageIndex(newIndex);
        }
    };

    const handleChangeSignature = (newIndex) => {
        if (newIndex >= 0 && newIndex < signatures.length) {
            setSelectedSignatureIndex(newIndex);
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <>
            <Header />
            <h1 className="page-title">Mina signaturer</h1>
            <div className="image-grid">
                <Button variant="contained" onClick={handleOpen}>Ny signatur</Button>
                <Modal
                    open={isModalOpen}
                    onClose={handleClose}
                    aria-labelledby="signature-pad-modal"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <SignaturePad onSave={handleSaveSignature} />
                        <Button onClick={handleClose}>Avbryt</Button>
                    </Box>
                </Modal>
                <div className="image-grid">
                    {signatures.map((data, index) => (
                        <div key={index} className="image-item" onClick={() => handleSignatureClick(index)}>
                            <img src={`https://storage.googleapis.com/pixelsbyme/${data.imageUrl}`} alt={`User signature`} />
                        </div>
                    ))}
                </div>
            </div>
            <h1 className="page-title">Mina bilder</h1>
            <div className="image-grid">
                {imageData.map((data, index) => (
                    <div key={index} className="image-item" onClick={() => setSelectedImageIndex(index)}>
                        <img src={`https://storage.googleapis.com/pixelsbyme/${data.watermarkedImage}`} alt={`User image`} />
                    </div>
                ))}
            </div>
            {selectedImageIndex !== null && (
                <ImageEditorModal
                    imageData={imageData}
                    selectedIndex={selectedImageIndex}
                    onClose={() => setSelectedSignatureIndex(null)}
                    signatureUrls={signatures.map((signature) => `https://storage.googleapis.com/pixelsbyme/${signature.imageUrl}`)}
                />
            )}
            {selectedSignatureIndex !== null && (
                <ImageModal
                    imageData={signatures}
                    selectedIndex={selectedSignatureIndex}
                    onClose={() => setSelectedSignatureIndex(null)}
                    onChangeImage={handleChangeSignature}
                />
            )}
        </>
    );
};

export default HomePage;
