import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './auth/AuthContext';
import SignIn from './auth/SignIn'; // The component for your login page
import HomePage from './home/HomePage'; // The component for your main authenticated page
import axios from 'axios';
import Register from './auth/Register';
import SettingsPage from './settings/SettingsPage';
import CreatePage from './create/CreatePage';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
// axios.interceptors.request.use(function (config) {
//   // Log the request here
//   console.log('Making request to', config.url, "with headers: ", config.headers);

//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });


const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<Register />} />

          <Route path="/create" element={<PrivateRoute><CreatePage /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
          <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export default App;
