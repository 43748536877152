import Chat from '../chat/Chat';
import Header from '../components/Header';
import './CreatePage.css';

const CreatePage = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="content">
                    <Chat />
                </div>
            </div>
        </>
    );
};

export default CreatePage;
