
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Header from '../components/Header';
import { useAuth } from '../auth/AuthContext';
import axios from 'axios';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://pixelsbyme.se/">
                PixelsByMe
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const defaultTheme = createTheme();

export default function Pricing() {
    const { logout, user } = useAuth();

    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Header />
            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 4 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    p={6}
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Inställningar
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sm={6}>
                        <Card>
                            <CardHeader
                                title="Konto"
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[200]
                                            : theme.palette.grey[700],
                                }}
                            />
                            <CardContent>
                                    <ul>
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                        >
                                            {user && user.email}
                                        </Typography>
                                        {user && user.isTrialUser ? (
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                            >
                                                Du är registrerad som testanvändare<br />
                                                Din provperiod går ut {user.trialExpiresAt}
                                            </Typography>

                                        ) : (
                                            <Typography></Typography>
                                        )}
                                    </ul>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant='contained' onClick={logout}>
                                    Logga ut
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Copyright sx={{ mt: 5 }} />
            </Container>
            {/* End footer */}
        </ThemeProvider>
    );
}
